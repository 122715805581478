import React, { useContext } from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import GreenCheckmarkWithTooltip from "./GreenCheckMarkWithTooltip";
import RedCrossWithTooltip from "./RedCrossWithTooltip";
import YellowBulbWithTooltip from "./YellowBulbWithTooltip";
import RedAlertWithTooltip from "./RedAlertWithTooltip";
import AuditContext from "../../store/audit-store";

export default function AuditStatsRow() {
  const auditContext = useContext(AuditContext);
  const { auditStats } = auditContext;
  const { kioskScannedCount,
    kioskUnScannedCount,
    kioskLaborTrackCount,
    kioskNonLaborTrackCount,
    midShiftScannedCount,
    midShiftLaborTrackCount,
    midShiftNonLaborTrackCount
  } = auditStats;

  // TODO: can refactor to auto-generate from objects
  return <Row alignmentHorizontal="center" spacing="medium large" >
    <Column>
      <Row spacing="medium medium">
        <Text>Kiosk</Text>
        <Row spacing="small">
          <GreenCheckmarkWithTooltip tooltipText="#Badged In" /><Text type="h300">{kioskScannedCount} |</Text>
        </Row>
        <Row spacing="small">
          <RedCrossWithTooltip /><Text type="h300"> {kioskUnScannedCount} | </Text>
        </Row>
        <Row spacing="small">
          <YellowBulbWithTooltip tooltipText="#Auto Labor Tracked" /><Text type="h300"> {kioskLaborTrackCount} |</Text>
        </Row>
        <Row spacing="small">
          <RedAlertWithTooltip tooltipText="#Not Auto Labor Tracked" /><Text type="h300"> {kioskNonLaborTrackCount}</Text>
        </Row>
      </Row>
    </Column>
    <Column>
      <Row spacing="medium medium">
        <Text>Mid-Shift</Text>
        <Row spacing="small">
          <GreenCheckmarkWithTooltip tooltipText="#Mid-Shift Moves" /><Text type="h300"> {midShiftScannedCount} |</Text>
        </Row>
        <Row spacing="small">
          <YellowBulbWithTooltip tooltipText="#Manual Labor Tracked" /><Text type="h300"> {midShiftLaborTrackCount} |</Text>
        </Row>
        <Row spacing="small">
          <RedAlertWithTooltip tooltipText="#Not Manual Labor Tracked" /><Text type="h300"> {midShiftNonLaborTrackCount}</Text>
        </Row>
      </Row>
    </Column>
  </Row>
}